import LogoAppSmall from "@ui/components/icons/LogoAppSmall";
import * as icons from "@ui/components/icons";
import { localize } from "@/helpers/_functions/i18n/dictionaryPathResolver";

const {
  patientsRights: { CREATE_PATIENT, ACCESS_PATIENT },
  prescribersRights: { CREATE_PRESCRIBER, ACCESS_PRESCRIBER },
  communitiesRights: { CREATE_COMMUNITY, ACCESS_COMMUNITY },
  planManagementRights: { ACCESS_PLAN_MANAGEMENT },
  reportsRights: { CREATE_REPORT, ACCESS_VIEW_REPORT },
  interventionsRights: { ACCESS_INTERVENTION, WRITE_RISK_ANALYSIS },
  entityInterventionsRights: { ACCESS_ENTITY_INTERVENTION },
  toursRights: { ACCESS_TOUR },
  contractsRights: { CREATE_CONTRACT },
  productsRights: { ACCESS_PRODUCT, ACCESS_GAS_OXYGEN_MONITORING, LIQUID_OXYGEN_ACCESS_MONITORING, LIQUID_OXYGEN_ACCESS_FRACTION },
  messagesRights: { CREATE_MESSAGE, ACCESS_MESSAGE },
  usersRights: { CREATE_USER, GET_USER, VIEW_USER },
  publicationsRights: { ADD_PRESCRIBER_BANNER, ADD_PRESCRIBER_NEWS, ACCESS_PUBLICATIONS },
  noticesManagerRights: { CREATE_NOTICE, ACCESS_NOTICES_MANAGER },
  alertsTemplatesRights: { CREATE_ALERT_TEMPLATE, ACCESS_ALERT_TEMPLATE },
  alertsRights: { ACCESS_ALERT },

  menuRights: {
    VIEW_MENU_MON_TRAVAIL,
    VIEW_MENU_INTERVENTIONS_TOURNEES,
    VIEW_MENU_ANOMALY_INTERVENTIONS,
    VIEW_MENU_ENTITY_INTERVENTIONS,
    VIEW_MENU_ANOMALY_ENTITY_INTERVENTIONS,
    VIEW_MENU_SEARCH_PATIENT,
    VIEW_MENU_EXPORT_MOUVEMENTS_STOCK,
    VIEW_MENU_EXPORT_DELIVERED_PAIRABLE_DEVICES,
    VIEW_MENU_EXPORT_CONSOMMATIONS,
    VIEW_MENU_STOCK_POLE,
    VIEW_MENU_REPORT
  },
  documentsRights: { VIEW_DOCUMENTS },
  supportTicketsRights: { ACCESS_SUPPORT_TICKETS },
  supportLogsRights: { ACCESS_SUPPORT_LOGS }
} = require("@common/services/acm/rights");

const features = require("../../services/features");

/**
 * When using dynamic redirect, we must use the route's path instead of the route's name.
 * Example : `routerLink: { path: "/foo" }` instead of `routerLink: { name: "foo" }`.
 */
const menu = [
  {
    title: localize("menus.administration.link--dashboard"),
    slug: "dashboard",
    icon: LogoAppSmall,
    routerLink: { name: "dashboard" },
    disabled: false,
    isHome: true
  },
  {
    title: localize("menus.administration.patients-medecins.title"),
    slug: "patients-medecins",
    icon: icons.iconUserClassicOutlined,
    disabled: false,
    items: [
      {
        title: localize("menus.administration.patients-medecins.patients.title"),
        slug: "patients",
        links: [
          {
            icon: icons.iconSearch,
            title: localize("menus.administration.patients-medecins.patients.link--patientSearch"),
            routerLink: {
              name: "patientSearch"
            },
            rights: [ACCESS_PATIENT, VIEW_MENU_SEARCH_PATIENT]
          },
          {
            icon: icons.iconAddPatientOutlined,
            title: localize("menus.administration.patients-medecins.patients.link--patientAddSearch"),
            routerLink: {
              name: "patientAddSearch"
            },
            rights: [CREATE_PATIENT]
          },
          {
            icon: icons.iconPatientOutlined,
            title: localize("menus.administration.patients-medecins.patients.link--patientsList"),
            routerLink: {
              name: "patients",
              alias: "patientsList"
            },
            rights: [ACCESS_PATIENT]
          }
        ]
      },
      {
        title: localize("menus.administration.patients-medecins.medecins.title"),
        slug: "medecins",
        links: [
          {
            icon: icons.iconAddPrescriberOutlined,
            title: localize("menus.administration.patients-medecins.medecins.link--prescriberAddSearch"),
            routerLink: {
              name: "prescriberAddSearch"
            },
            rights: [CREATE_PRESCRIBER]
          },
          {
            icon: icons.iconUserDoctorOutlined,
            title: localize("menus.administration.patients-medecins.medecins.link--prescribersList"),
            routerLink: {
              name: "prescribers",
              alias: "prescribersList"
            },
            rights: [ACCESS_PRESCRIBER]
          },
          {
            icon: icons.iconAddPrescribersGroupOutlined,
            title: localize("menus.administration.patients-medecins.medecins.link--prescribersGroupAddSearch"),
            routerLink: {
              name: "prescribersGroupAddSearch"
            },
            rights: [CREATE_PRESCRIBER]
          },
          {
            icon: icons.iconPrescribersGroup,
            title: localize("menus.administration.patients-medecins.medecins.link--prescribersGroups"),
            routerLink: {
              name: "prescribersGroups",
              alias: "prescribersGroupsList"
            },
            rights: [ACCESS_PRESCRIBER]
          }
        ]
      },
      {
        title: localize("menus.administration.patients-medecins.communities.title"),
        slug: "collectivites",
        links: [
          {
            icon: icons.iconAddCommunityOutlined,
            title: localize("menus.administration.patients-medecins.communities.link--communityAddSearch"),
            routerLink: {
              name: "communityAddSearch"
            },
            rights: [CREATE_COMMUNITY]
          },
          {
            icon: icons.iconCommunityOutlined,
            title: localize("menus.administration.patients-medecins.communities.link--communitiesList"),
            routerLink: {
              name: "communities",
              alias: "communitiesList"
            },
            rights: [ACCESS_COMMUNITY]
          }
        ]
      }
    ]
  },
  {
    title: localize("menus.administration.interventions-tours.title"),
    slug: "interventions-tournees",
    icon: icons.iconInterventionOutlined,
    disabled: false,
    items: [
      {
        title: localize("menus.administration.interventions-tours.interventions.title"),
        slug: "interventions",
        rights: [VIEW_MENU_INTERVENTIONS_TOURNEES],
        links: [
          {
            icon: icons.iconCalendarMonth,
            title: localize("menus.administration.interventions-tours.interventions.link--interventionsPlanning"),
            routerLink: {
              name: "interventionsPlanning"
            },
            rights: [ACCESS_PLAN_MANAGEMENT]
          },
          {
            icon: icons.iconList,
            title: localize("menus.administration.interventions-tours.interventions.link--interventionsList"),
            routerLink: {
              name: "interventions"
            },
            rights: [ACCESS_INTERVENTION]
          },
          {
            icon: icons.iconError,
            title: localize("menus.administration.interventions-tours.interventions.link--interventionsWithAnomaly"),
            routerLink: {
              name: "interventionsWithAnomaly"
            },
            rights: [ACCESS_INTERVENTION, VIEW_MENU_ANOMALY_INTERVENTIONS]
          }
        ]
      },
      {
        title: localize("menus.administration.interventions-tours.tours.title"),
        slug: "tournees",
        rights: [VIEW_MENU_INTERVENTIONS_TOURNEES],
        links: [
          {
            icon: icons.iconInterventionOutlined,
            title: localize("menus.administration.interventions-tours.tours.link--tours"),
            routerLink: {
              name: "interventionsTour"
            },
            rights: [ACCESS_TOUR]
          }
        ]
      },
      {
        title: localize("menus.administration.interventions-tours.work.title"),
        slug: "mon-travail",
        rights: [VIEW_MENU_MON_TRAVAIL],
        links: [
          {
            icon: icons.iconInterventionOutlined,
            title: localize("menus.administration.interventions-tours.work.link--productsStock"),
            rights: [ACCESS_PRODUCT],
            routerLink: {
              name: "productsStock"
            }
          },
          {
            icon: icons.iconInterventionOutlined,
            title: localize("menus.administration.interventions-tours.work.link--interTour"),
            rights: [ACCESS_INTERVENTION],
            routerLink: {
              name: "interventionsSingleTour"
            }
          },
          {
            icon: icons.iconCalendarMonth,
            title: localize("menus.administration.interventions-tours.work.link--interCalendar"),
            rights: [ACCESS_INTERVENTION],
            routerLink: {
              name: "interventionsCalendar"
            }
          },
          {
            icon: icons.iconList,
            title: localize("menus.administration.interventions-tours.work.link--interWaiting"),
            rights: [ACCESS_INTERVENTION],
            routerLink: {
              name: "interventionsWaitingStatus"
            }
          },
          {
            icon: icons.iconList,
            title: localize("menus.administration.interventions-tours.work.link--interReady"),
            rights: [ACCESS_INTERVENTION],
            routerLink: {
              name: "interventionsReadyStatus"
            }
          },
          {
            icon: icons.iconList,
            title: localize("menus.administration.interventions-tours.work.link--riskAnalysisToClose"),
            rights: [WRITE_RISK_ANALYSIS],
            routerLink: {
              name: "interventionsWithRiskAnalysisToClose"
            }
          }
        ]
      }
    ]
  },
  {
    title: localize("menus.administration.services.title"),
    slug: "prestation-ordonnances",
    icon: icons.iconPrescription,
    disabled: false,
    items: [
      {
        title: localize("menus.administration.services.contracts.title"),
        slug: "prestations",
        links: [
          {
            icon: icons.iconMedical,
            title: localize("menus.administration.services.contracts.link--createService"),
            routerLink: {
              name: "contractsAddPatient"
            },
            rights: [CREATE_CONTRACT]
          }
        ]
      },
      {
        title: localize("menus.administration.services.documents.title"),
        slug: "documents",
        links: [
          {
            icon: icons.iconPrescription,
            title: localize("menus.administration.services.documents.link--documentList"),
            routerLink: {
              name: "documentsList"
            },
            rights: [VIEW_DOCUMENTS]
          }
        ]
      }
    ]
  },
  {
    title: localize("menus.administration.products.title"),
    slug: "products",
    icon: icons.iconMedicalDevice,
    disabled: false,
    items: [
      {
        title: localize("menus.administration.products.products.title"),
        slug: "all-products",
        links: [
          {
            icon: icons.iconMedicalDevice,
            title: localize("menus.administration.products.products.link--productsList"),
            routerLink: {
              name: "products",
              alias: "productsList"
            },
            rights: [ACCESS_PRODUCT]
          },
          {
            icon: icons.iconMedicalDevice,
            title: localize("menus.administration.products.products.link--exportMouvementsStock"),
            routerLink: {
              name: "exportMouvementsStock"
            },
            rights: [VIEW_MENU_EXPORT_MOUVEMENTS_STOCK]
          },
          {
            icon: icons.iconMedicalDevice,
            title: localize("menus.administration.products.products.link--exportDeliveredPairableDevices"),
            routerLink: {
              name: "exportDeliveredPairableDevices"
            },
            rights: [VIEW_MENU_EXPORT_DELIVERED_PAIRABLE_DEVICES]
          },
          {
            icon: icons.iconMedicalDevice,
            title: localize("menus.administration.products.products.link--exportConsommations"),
            routerLink: {
              name: "exportConsommations"
            },
            rights: [VIEW_MENU_EXPORT_CONSOMMATIONS]
          }
        ]
      },
      {
        title: localize("menus.administration.products.stock.title"),
        slug: "stock",
        links: [
          {
            icon: icons.iconMedicalDevice,
            title: localize("menus.administration.products.stock.link--productsStockPole"),
            rights: [VIEW_MENU_STOCK_POLE],
            routerLink: {
              name: "productsStockPole"
            }
          },
          {
            icon: icons.iconInterventionOutlined,
            title: localize("menus.administration.products.stock.link--stockTransfert"),
            rights: [VIEW_MENU_STOCK_POLE],
            routerLink: {
              name: "productsStockTransfert"
            }
          }
        ]
      },
      {
        title: localize("menus.administration.products.o2Gas.title"),
        slug: "gas-oxygen",
        links: [
          {
            icon: icons.iconMedicalDevice,
            title: localize("menus.administration.products.o2Gas.link--productAgaMonitoring"),
            routerLink: {
              name: "productAgaMonitoring"
            },
            rights: [ACCESS_GAS_OXYGEN_MONITORING]
          },
          {
            icon: icons.iconMedicalDevice,
            title: localize("menus.administration.products.o2Gas.link--productAgaHistory"),
            routerLink: {
              name: "productAgaHistory"
            },
            rights: [ACCESS_GAS_OXYGEN_MONITORING]
          }
        ]
      },
      {
        title: localize("menus.administration.products.o2Liquid.title"),
        slug: "liquid-oxygen",
        links: [
          {
            icon: icons.iconMedicalDevice,
            title: localize("menus.administration.products.o2Liquid.link--liquidOxygen"),
            routerLink: {
              name: "productTankMonitoring"
            },
            rights: [LIQUID_OXYGEN_ACCESS_MONITORING]
          },
          {
            icon: icons.iconMedicalDevice,
            title: localize("menus.administration.products.o2Liquid.link--fraction"),
            routerLink: {
              name: "productO2fraction"
            },
            rights: [LIQUID_OXYGEN_ACCESS_FRACTION]
          }
        ]
      },
      {
        title: localize("menus.administration.products.notices.title"),
        slug: "notices-manager",
        links: [
          {
            icon: icons.iconProductNotice,
            title: localize("menus.administration.products.notices.link--noticesList"),
            routerLink: {
              name: "noticesManagerList"
            },
            rights: [ACCESS_NOTICES_MANAGER]
          },
          {
            icon: icons.iconProductAddNotice,
            title: localize("menus.administration.products.notices.link--noticesAdd"),
            routerLink: {
              name: "noticesManagerAdd"
            },
            rights: [CREATE_NOTICE]
          }
        ]
      }
    ]
  },
  {
    title: localize("menus.administration.message.title"),
    slug: "messages",
    icon: icons.iconDiscussion,
    notificationName: "countUnreadConversation",
    disabled: false,
    items: [
      {
        title: localize("menus.administration.message.messages.title"),
        slug: "messages-sub",
        links: [
          {
            icon: icons.iconAddMessage,
            title: localize("menus.administration.message.messages.link--conversationsAdd"),
            routerLink: {
              name: "conversationsAdd"
            },
            rights: [CREATE_MESSAGE]
          },
          {
            icon: icons.iconDiscussion,
            notificationName: "countUnreadConversation",
            title: localize("menus.administration.message.messages.link--conversationsList"),
            slug: "messages",
            routerLink: {
              name: "conversationsList"
            },
            rights: [ACCESS_MESSAGE]
          }
        ]
      }
    ]
  },
  {
    title: localize("menus.administration.alert.title"),
    slug: "alerts",
    icon: icons.iconAlert,
    disabled: false,
    items: [
      {
        title: localize("menus.administration.alert.alerts.title"),
        slug: "alerts-sub",
        links: [
          {
            icon: icons.iconDashboard,
            title: localize("menus.administration.alert.alerts.link--alertsDashboard"),
            routerLink: {
              name: "alertsDashboard"
            },
            rights: [ACCESS_ALERT]
          },
          {
            icon: icons.iconAlertAdd,
            title: localize("menus.administration.alert.alerts.link--alertTemplatesAdd"),
            routerLink: {
              name: "alertTemplatesAdd"
            },
            rights: [CREATE_ALERT_TEMPLATE]
          },
          {
            icon: icons.iconAlert,
            title: localize("menus.administration.alert.alerts.link--alertTemplatesList"),
            routerLink: {
              name: "alertTemplatesList"
            },
            rights: [ACCESS_ALERT_TEMPLATE]
          },
          {
            icon: icons.iconMailOutlined,
            title: localize("menus.administration.alert.alerts.link--alertsReminders"),
            routerLink: {
              name: "alertsReminders"
            },
            rights: [ACCESS_ALERT]
          }
        ]
      }
    ]
  },
  {
    title: localize("menus.administration.publications.title"),
    slug: "publications",
    icon: icons.iconImages,
    disabled: false,
    items: [
      {
        title: localize("menus.administration.publications.publications-subs.title"),
        slug: "publications-subs",
        links: [
          {
            icon: icons.iconAddBanner,
            title: localize("menus.administration.publications.publications-subs.link--publicationsBannerAdd"),
            routerLink: {
              name: "publicationsBannerAdd"
            },
            rights: [ADD_PRESCRIBER_BANNER]
          },
          {
            icon: icons.iconAddNews,
            title: localize("menus.administration.publications.publications-subs.link--publicationsNewsAdd"),
            routerLink: {
              name: "publicationsNewsAdd"
            },
            rights: [ADD_PRESCRIBER_NEWS]
          },
          {
            icon: icons.iconList,
            title: localize("menus.administration.publications.publications-subs.link--publicationsList"),
            routerLink: {
              name: "publicationsList"
            },
            rights: [ACCESS_PUBLICATIONS]
          }
        ]
      }
    ]
  },
  {
    title: localize("menus.administration.settings.title"),
    slug: "settings",
    icon: icons.iconSettings,
    notificationName: "countUnreadSupportTickets",
    disabled: false,
    rights: [VIEW_USER],
    items: [
      {
        title: localize("menus.administration.settings.users.title"),
        slug: "utilisateurs",
        links: [
          {
            icon: icons.iconAddUserClassicOutlined,
            title: localize("menus.administration.settings.users.link--usersSearch"),
            routerLink: {
              name: "usersSearch",
              alias: "usersSearch"
            },
            rights: [CREATE_USER]
          },
          {
            icon: icons.iconUserClassicOutlined,
            title: localize("menus.administration.settings.users.link--usersList"),
            routerLink: {
              name: "users",
              alias: "usersList"
            },
            rights: [GET_USER]
          }
        ]
      },
      {
        title: localize("menus.administration.settings.logs.title"),
        slug: "logs",
        links: [
          {
            icon: icons.iconList,
            title: localize("menus.administration.settings.logs.link--logsList"),
            routerLink: {
              name: "supportLogs"
            },
            rights: [ACCESS_SUPPORT_LOGS]
          }
        ]
      }
    ]
  }
];

if (__APP_ENV__ === "production") {
  const entry = menu.find(item => item.slug === "settings");
  entry.items.push({
    title: localize("menus.administration.settings.tickets.title"),
    slug: "tickets",
    links: [
      {
        icon: icons.iconTags,
        notificationName: "countUnreadSupportTickets",
        slug: "tickets",
        title: localize("menus.administration.settings.tickets.link--ticketsList"),
        routerLink: {
          name: "supportTicketsList"
        },
        rights: [ACCESS_SUPPORT_TICKETS]
      }
    ]
  });
}

const entry = menu.find(item => item.slug === "interventions-tournees");
if (features.isCommunityEntityInterventionsEnabled()) {
  entry.items.push({
    title: localize("menus.administration.interventions-tours.communities-delivery.title"),
    slug: "livraisons-collectivite",
    rights: [VIEW_MENU_ENTITY_INTERVENTIONS],
    links: [
      {
        icon: icons.iconList,
        title: localize("menus.administration.interventions-tours.communities-delivery.link--deliveryList"),
        routerLink: {
          name: "entityInterventions"
        },
        rights: [ACCESS_ENTITY_INTERVENTION]
      },
      {
        icon: icons.iconError,
        title: localize("menus.administration.interventions-tours.communities-delivery.link--deliveryWithAnomaly"),
        routerLink: {
          name: "entityInterventionsWithAnomaly"
        },
        rights: [ACCESS_ENTITY_INTERVENTION, VIEW_MENU_ANOMALY_ENTITY_INTERVENTIONS]
      }
    ]
  });
}

entry.items.push({
  title: localize("menus.administration.interventions-tours.report.title"),
  slug: "report",
  rights: [VIEW_MENU_REPORT],
  links: [
    {
      icon: icons.iconAddReport,
      title: localize("menus.administration.interventions-tours.report.link--addReport"),
      routerLink: {
        name: "addReport"
      },
      rights: [CREATE_REPORT]
    },
    {
      icon: icons.iconReport,
      title: localize("menus.administration.interventions-tours.report.link--viewReports"),
      routerLink: {
        name: "viewReports"
      },
      rights: [ACCESS_VIEW_REPORT]
    }
  ]
});

export default menu;
